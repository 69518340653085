<template>
  <div class="competition">
    <banner/>
    <div class="text-item">
      <div class="content">
        <div class="c-title anime">
          报名流程
          <span></span>
        </div>
        <div class="desc c anime">
          （报名截止日期2021-5月8日24点）<br />
          第一步——注册账号—登录账号—客户中心—个人中心<br />
          第二步——参赛报名-完善信息-提交报名-报名成功<br />
        </div>
      </div>
    </div>
    <div class="text-item">
      <div class="content">
        <div class="c-title anime">
          作品上传流程
          <span></span>
        </div>
        <div class="desc anime c">
          （作品提交截止日期2021-6月10日24点）<br />
          第一步——个人中心-上传作品<br />
          第二步——完善作品信息<br />
          第三步——保存-提交-参赛成功<br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from './components/banner';
export default {
  components:{banner},
  data() {
    return {
      awardList: [
        { id: 1, title: "最佳优秀设计奖", num: "1项", content: "3000RMB/项" },
        { id: 2, title: "金奖", num: "8项", content: "2000RMB/项" },
        { id: 3, title: "银奖", num: "10项", content: "1000RMB/项" },
        { id: 4, title: "优胜奖", num: "20项", content: "证书+奖杯" },
        { id: 5, title: "最佳指导导师奖", num: "5项", content: "证书+奖杯" },
        { id: 6, title: "最佳组织奖", num: "1项", content: "证书+奖杯" },
      ],
    };
  },
  mounted() {
    this.$parent.finish ? this.$parent.finish() : null;
  },
};
</script>
<style lang="scss" scoped>
.competition {
  color: white;
  .c-title {
    text-align: center;
    font-size: 30px;
    color: #fffefe;
    span {
      width: 61px;
      height: 4px;
      display: block;
      background-color: $c;
      box-shadow: 0 0 15px 5px fade-out($color: $c2, $amount: 0.5);
      margin: 20px auto 0;
    }
  }
  .text-item {
    margin-top: 5vw;
    .content {
      .desc {
        margin-top: 3vw;
        font-size: 20px;
        line-height: 3vw;
        letter-spacing: 2px;
        &.c {
          text-align: center;
        }
      }
    }
  }
  .flow {
    margin-top: 10vw;
    .flow-main {
      margin-top: 4vw;
      display: flex;
      justify-content: space-around;
      overflow: hidden;
      position: relative;
      align-items: center;
      & > p {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 20px;
        text-align: center;
        color: #ffffff;
        letter-spacing: 4px;
        line-height: 115px;
      }
      .icon {
        position: relative;
        z-index: 9;
      }
      .item {
        text-align: center;
        padding-top: 25px;
        position: relative;
        z-index: 9;
        &.t {
          padding-top: 0;
          padding-bottom: 25px;
        }
        p {
          width: 30px;
          height: 30px;
          background-image: linear-gradient(90deg, #2788ff 0%, #272fff 100%),
            linear-gradient(#272fff, #272fff);
          text-align: center;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          font-size: 18px;
          color: white;
          margin: 20px auto;
        }
        span {
          display: block;
          font-size: 20px;
        }
      }
    }
  }
  .award {
    margin-top: 10vw;
    .desc {
      margin-top: 4vw;
      font-size: 20px;
      line-height: 100px;
      letter-spacing: 2px;
      &.c {
        text-align: center;
      }
    }
    .list {
      display: flex;
      justify-content: space-around;
      .item {
        width: calc(90% / 6);
        background-color: #100f1e;
        border: solid 6px #272fff;
        padding: 25px 5px;
        @include box();
        p {
          margin-top: 25px;
          font-size: 18px;
          text-align: center;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>